import BaseAPIClient from './client'

import Auth from '@/auth'

class APIClient {
  constructor(endpoint) {
    this.client = new BaseAPIClient(endpoint)
  }


  getProfile() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, "/profile").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  postProfile(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, "/profile", params).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  postJobIdEntry(jobId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, "/jobs/" + jobId + '/entry', {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  postProfileTemporaryProfileId(temporaryProfileId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, "/profile/" + temporaryProfileId, {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          console.error(error);
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getWorks() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.get(user, "/works?limit=1000").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getEntries() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.get(user, "/entries?limit=1000").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getMessageChats() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.get(user, "/message-chats").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getMessageChatsEntryId(entryId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.get(user, "/message-chats/" + entryId).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  postEntryIdDecline(entryId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, "/entries/" + entryId + '/decline', {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getEntryId(entryId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, "/entries/" + entryId).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }


  getEntryIdOffers(entryId, reportId = null) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        if( reportId ){
          this.client.get(user, `/entries/${entryId}/offers?check_report_id=${reportId}`,).then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject({
              code: "APIErrorException",
              error: error
            })
          });
        }else{
          this.client.get(user, "/entries/" + entryId + '/offers',).then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject({
              code: "APIErrorException",
              error: error
            })
          });
        }


      })();
    });
  }
  //  MYF_2-896 条件オファー削除により停止
  // postEntryIdOffers(entryId, params) {
  //   console.log(params)
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       const user = await Auth.getCurrentUser().catch(() => { return null });
  //       console.log(params)
  //       this.client.post(user, "/entries/" + entryId + '/offers', params).then((response) => {
  //         console.log(response)
  //         resolve(response.data)
  //       }).catch((error) => {
  //         reject({
  //           code: "APIErrorException",
  //           error: error
  //         })
  //       });
  //     })();
  //   });
  // }

  // getEntryIdOfferId(entryId, offerId, isReserve) {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       const user = await Auth.getCurrentUser().catch(() => { return null });
  //       if (isReserve) {
  //         this.client.get(user, "/entries/" + entryId + '/offers/' + offerId + '?isReserve=true').then((response) => {
  //           console.log(response)
  //           resolve(response.data)
  //         }).catch((error) => {
  //           reject({
  //             code: "APIErrorException",
  //             error: error
  //           })
  //         });
  //       } else {
  //         this.client.get(user, "/entries/" + entryId + '/offers/' + offerId).then((response) => {
  //           resolve(response.data)
  //         }).catch((error) => {
  //           reject({
  //             code: "APIErrorException",
  //             error: error
  //           })
  //         });

  //       }
  //     })();
  //   });
  // }

  // postEntryIdOfferIdSend(entryId, offerId) {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       const user = await Auth.getCurrentUser().catch(() => { return null });

  //       this.client.post(user, "/entries/" + entryId + '/offers/' + offerId + '/send', {}).then((response) => {
  //         resolve(response.data)
  //       }).catch((error) => {
  //         reject({
  //           code: "APIErrorException",
  //           error: error
  //         })
  //       });
  //     })();
  //   });
  // }


  // postEntryIdOfferIdCancel(entryId, offerId) {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       const user = await Auth.getCurrentUser().catch(() => { return null });

  //       this.client.post(user, "/entries/" + entryId + '/offers/' + offerId + '/cancel', {}).then((response) => {
  //         resolve(response.data)
  //       }).catch((error) => {
  //         reject({
  //           code: "APIErrorException",
  //           error: error
  //         })
  //       });
  //     })();
  //   });
  // }

  postEntryIdOfferIdPurchaseOrderKey(entryId, offerId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, "/entries/" + entryId + '/offers/' + offerId + '/purchaseOrder/key', {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  postEntryIdOfferIdPurchaseOrder(entryId, offerId, dlKey) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, "/entries/" + entryId + '/offers/' + offerId + '/purchaseOrder', dlKey).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          console.log(error)
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getEntryIdContractGroupId(entryId, contractGroupId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.get(user, "/entries/" + entryId + '/contractGroups/' + contractGroupId).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  postEntryIdContractGroupIdReport(entryId, contractGroupId, report) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, "/entries/" + entryId + '/contractGroups/' + contractGroupId + '/report', report).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getMessageChatsToken() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.get(user, "/message-chats/token").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getProfilePortfolio() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.get(user, "/profile/portfolio").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  deletePortfolio(PortfolioId) {

    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, "/portfolio/" + PortfolioId + "/delete", {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  //  MYF_2-896 条件オファー削除により停止
  // postBank(params) {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       const user = await Auth.getCurrentUser().catch(() => { return null });

  //       this.client.post(user, "/bank", params).then((response) => {
  //         resolve(response.data)
  //       }).catch((error) => {
  //         reject({
  //           code: "APIErrorException",
  //           error: error
  //         })
  //       });
  //     })();
  //   });
  // }

  // getBank() {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       const user = await Auth.getCurrentUser().catch(() => { return null });

  //       this.client.get(user, "/bank").then((response) => {
  //         resolve(response.data)
  //       }).catch((error) => {
  //         reject({
  //           code: "APIErrorException",
  //           error: error
  //         })
  //       });
  //     })();
  //   });
  // }

  // getConstantsBanks(query) {
	// 	return new Promise((resolve, reject) => {
  //     (async () => {
  //       const user = await Auth.getCurrentUser().catch(() => { return null });
  //       console.log("user", user)
  //       let qs = ''
  //       if (query) {
  //         console.log(query)
  //         if (query.query) {
  //           qs += 'query=' + encodeURIComponent(query.query)
  //         }
  //       }
  //       this.client.get(user, '/constants/banks?' + qs).then(response => {
  //         if (response.status == 200) {
  //           resolve(response.data)
  //         }
  //         else {
  //           reject(response)
  //         }
  //       }).catch(e => {
  //         reject(e)
  //       });
  //     })();
	// 	})
	// }

	// getConstantsBanksBankCode(bankCode, query) {
	// 	return new Promise((resolve, reject) => {
  //     (async () => {
  //       const user = await Auth.getCurrentUser().catch(() => { return null });
  //       let qs = ''
  //       if (query) {
  //         if (query.query) {
  //           qs += 'query=' + encodeURIComponent(query.query)
  //         }
  //       }
  //       this.client.get(user, '/constants/banks/' + bankCode + '?' + qs).then(response => {
  //         if (response.status == 200) {
  //           resolve(response.data)
  //         }
  //         else {
  //           reject(response)
  //         }
  //       }).catch(e => {
  //         reject(e)
  //       });
  //     })();
	// 	})
	// }

  getMuteWords() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.get(user, "/mute/words").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  postMuteWords(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, "/mute/words", params).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  postMuteWordsCode(code) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, "/mute/words/" + code, {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getScouts(page, filter, sort) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        const queries = []

        if (page) {
          queries.push('page=' + encodeURIComponent(page))
        }

        if (filter) {
          queries.push('filter=' + encodeURIComponent(filter))
        }

        if (sort) {
          queries.push('sort=' + encodeURIComponent(sort))
        }

        const queryString = queries.length > 0 ? '?' + queries.join('&') : ''

        this.client.get(user, "/scouts" + queryString, {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getScout(scoutId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, "/scouts/" + scoutId).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  postScout(scoutId) {  // scoutIdのスカウトを辞退
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, "/scouts/" + scoutId + "/decline", {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }
  //  MYF_2-896 条件オファー削除により停止
  // getVerifyStatus() {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       const user = await Auth.getCurrentUser().catch(() => { return null });
  //       this.client.get(user, "/verify/status").then((response) => {
  //         resolve(response.data)
  //       }).catch((error) => {
  //         reject({
  //           code: "APIErrorException",
  //           error: error
  //         })
  //       });
  //     })();
  //   });
  // }

  // postVerify(params) {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       const user = await Auth.getCurrentUser().catch(() => { return null });
  //       this.client.post(user, "/verify", params).then((response) => {
  //         resolve(response.data)
  //       }).catch((error) => {
  //         reject({
  //           code: "APIErrorException",
  //           error: error
  //         })
  //       });
  //     })();
  //   });
  // }

  // getVerify() {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       const user = await Auth.getCurrentUser().catch(() => { return null });
  //       this.client.get(user, "/verify", {}).then((response) => {
  //         resolve(response.data)
  //       }).catch((error) => {
  //         reject({
  //           code: "APIErrorException",
  //           error: error
  //         })
  //       });
  //     })();
  //   });
  // }

  getRewards(page, from, to, companyName) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        const queries = []

        if (page) {
          queries.push('page=' + encodeURIComponent(page))
        }

        if (from) {
          queries.push('from=' + encodeURIComponent(from))
        }

        if (to) {
          queries.push('to=' + encodeURIComponent(to))
        }

        if (companyName) {
          queries.push('companyName=' + encodeURIComponent(companyName))
        }

        const queryString = queries.length > 0 ? '?' + queries.join('&') : ''

        this.client.get(user, "/rewards" + queryString, {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  postNDA(confirmed) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, "/nda", {
          confirmed: confirmed
        }).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getNDA() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, "/nda", {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getProfileStatus() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, "/profile/status").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  postEmail(email) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, "/email", {
          email: email
        }).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  postEmailVerify(hash) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, "/email/verify", {
          hash: hash
        }).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getSNSProfile() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, "/sns/profile").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getMessageChatsEntryIdFileId(entryId, fileId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, `/message-chats/${entryId}/file/${fileId}`).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  postMessageChatsEntryIdFile(entryId, data, fileName, mimeType, size) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, `/message-chats/${entryId}/file`, {
          data,
          fileName,
          mimeType,
          size,
        }).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getFollows() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.get(user, "/follows").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  postFollows(companyId, followedDevice, scoutId) {  // 企業をフォローへ追加
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, `/follows/${companyId}/add`, { followedDevice, scoutId }).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  postFollowsCompanyId(companyId) {  // 企業をフォローから解除
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, `/follows/${companyId}/delete`, {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  getSkillMatchJobs() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.get(user, "/jobs/skill").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getWishNearJobs() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.get(user, "/jobs/wish").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getInterestJobs() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.get(user, "/jobs/interests").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  search(conditions) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, "/jobs", conditions).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  searchInterestRecommendJobs() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, "/jobs/recommend/interests2").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  getCompanyAbuse(companyId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.get(user, "/company/abuse/"+ companyId).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  postCompanyAbuse(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, "/company/abuse", params).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  postEntryIdContractGroupIdReportCancel(entryId, contractGroupId, params) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, "/entries/" + entryId + '/contractGroups/' + contractGroupId + '/report/cancel', params).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  postEntryIdContractGroupIdFile(entryId, contractGroupId, params) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, "/entries/" + entryId + '/contractGroups/' + contractGroupId + '/file', params).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getEntryIdContractGroupIdFileIdKeygenerate(entryId, contractGroupId, fileId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, "/entries/" + entryId + '/contractGroups/' + contractGroupId + '/file/' + 'keyGenerate/' + fileId).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getEntryIdContractGroupIdDownloadKeyDownload(entryId, contractGroupId, downloadKey) {
    console.log("key is",downloadKey)
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, "/entries/" + entryId + '/contractGroups/' + contractGroupId + '/file/' + 'download/' + downloadKey ).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  // 業務報告書PDF生成
  postReportIdContractReportKey(entryId, contractGroupId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, "/entries/" + entryId + '/contractGroups/' + contractGroupId + '/reportpdf', {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getEntryProfile(entryId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, "/entries/" + entryId + "/profile").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  postInterests(jobId, params) {  //　お気に入りへ案件を追加
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, `/jobs/interests/${jobId}/add`, params).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  postInterestsJobId(jobId) {  // お気に入りから削除
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });

        this.client.post(user, `/jobs/interests/${jobId}/delete`, {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  postWithdrawal(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, `/withdrawal`, params).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  getSNSProviders() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, "/sns/providers").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  postSNSDissociate(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, `/sns/dissociate`, params).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  postSNSAssociate(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, `/sns/associate`, params).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getWorksAlerts() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, "/works/alerts").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  postPasswordMail() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, `/password/mail`, {}).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });
      })();
    });
  }

  getMemberTopics() {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, "/topics").then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

  getMemberTopic(topicId) {
    return new Promise((resolve, reject) => {
      (async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.get(user, `/topics/${topicId}`).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });

      })();
    });
  }

	updateViewHistory(companyId) {
		return new Promise((resolve, reject) => {
			(async () => {
				const user = await Auth.getCurrentUser().catch(() => { resolve() });
				if (user == null) resolve();

				this.client.get(user, `/company/history/${companyId}`).then(()=>{
					resolve()
				}).catch((error) => {
					reject({
						code: "APIErrorException",
						error: error
					})
				});

			})();
		});
	}


  // スカウトclickログ用
	postScoutLog(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        console.log(params)
        const user = await Auth.getCurrentUser().catch(() => { return null });
        this.client.post(user, `/clicklog`, params).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({
            code: "APIErrorException",
            error: error
          })
        });        
      })();
    });
	}

  //  MYF_2-896 条件オファー削除により停止
  // postInvoiceNumber(invoiceInfo) {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       const user = await Auth.getCurrentUser().catch(() => { return null });
  //       this.client.post(user, "/invoice_number", {
  //         invoice_status: invoiceInfo.invoiceStatus,
  //         invoice_number: invoiceInfo.invoiceNumber
  //       }).then((response) => {
  //         resolve(response.data)
  //       }).catch((error) => {
  //         reject({
  //           code: "APIErrorException",
  //           error: error
  //         })
  //       });
  //     })();
  //   });
  // }

  // getInvoiceNumber() {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       const user = await Auth.getCurrentUser().catch(() => { return null });
  //       this.client.get(user, "/invoice_number", {}).then((response) => {
  //         resolve(response.data)
  //       }).catch((error) => {
  //         reject({
  //           code: "APIErrorException",
  //           error: error
  //         })
  //       });
  //     })();
  //   });
  // }

	getUserAttribute() {
		return new Promise((resolve, reject) => {
			(async () => {
        const user = await Auth.getCurrentUser().catch(() => { return null });
        if( user == null ) {
          resolve([]);
        }
			this.client.get(user, "/system/attribute", {}).then((response) => {
				resolve(response.data)
			}).catch((error) => {
				reject({
				code: "APIErrorException",
				error: error
				})
			});
			})();
		});
	}

}

export default new APIClient(process.env.VUE_APP_MEMBER_API_ENDPOINT)
