import Vue from 'vue'

import CodeHelper from '@/helpers/code-helper'

import PublicAPI from '@/api/public'
import MemberAPI from '@/api/member'

export default {
  namespaced: true,
  state: {
    checkedJobCategories: [],
    checkedSkills: [],
    checkedWorkStyles: [],
    checkedLocations: [],
    unitPriceMin: { name: "", code: null },
    unitPriceMax: { name: "", code: null },
    checkedWorkingTime: [],
    checkedFrequenciesOfGoingToOffice: [],
    checkedFeatures: [],
    skillKeyword: "",
    keyword: "",
    onlyNewJobs: false,
    onlyHiringJobs: false,
    companyId: 0,

    skillMatchEnabled: false,

    searchResults: [],
    searchResultCount: null,
    searchResultIndexFrom: 0,
    searchResultIndexTo: 0,
    searchPreviewCount: null,

    sort: { 'code': '01', 'name': '新着順' },

    alternateResults: [],
    recommendTitle: "",
    recommendedJobs: [],
    interestRecommendJobs: [],

    currentPage: 1,
    pageSize: 20,

    searchHistoryPaths: [],
    searchHistoryPathsMypage: [],

    searchHistoryTexts: [],
    searchHistoryTextsMypage: [],

    searchConditionText: '',
    searchConditionCode: '',

    searchConditionCount: 0,

    // パンくずリスト
    breadCrumbBeforeMiddle: { text: '', path: '' },
    
    breadCrumbMiddle: { text: '', path: '' },

    breadCrumbLast: { text: '', path: '' },

    // h1
    h1Text: '',

  },
  mutations: {
    setCheckedJobCategories(state, checkedItems) {
      state.checkedJobCategories = checkedItems
    },
    setCheckedSkills(state, checkedItems) {
      state.checkedSkills = checkedItems
    },
    setCheckedWorkStyles(state, checkedItems) {
      state.checkedWorkStyles = checkedItems
    },
    setCheckedLocations(state, checkedItems) {
      state.checkedLocations = checkedItems
    },
    setUnitPriceMin(state, value) {
      Vue.set(state, "unitPriceMin", value)
    },
    setUnitPriceMax(state, value) {
      Vue.set(state, "unitPriceMax", value)
    },
    setCheckedWorkingTime(state, checkedItems) {
      // let formatCode = {...checkedItems}
      // let formatCode = Object.assign([], checkedItems);
      // Object.keys(formatCode).forEach(key => {
      //   formatCode[key].name = '週' + formatCode[key].name;
      // });
      // console.log('formatCode', formatCode)
      // console.log('checkedItems', checkedItems)
      // state.checkedWorkingTime = formatCode
      state.checkedWorkingTime = checkedItems
    },
    setCheckedFrequenciesOfGoingToOffice(state, checkedItems) {
      state.checkedFrequenciesOfGoingToOffice = checkedItems
    },
    setCheckedFeatures(state, checkedItems) {
      state.checkedFeatures = checkedItems
    },
    setKeyword(state, keyword) {
      state.keyword = keyword
    },
    setSkillKeyword(state, keyword) {
      state.skillKeyword = keyword
    },
    setSort(state, sort) {
      state.sort = sort
    },
    setOnlyNewJobs(state, bool) {
      state.onlyNewJobs = bool
    },
    setOnlyHiringJobs(state, bool) {
      state.onlyHiringJobs = bool
    },
    setSkillMatchEnabled(state, bool) {
      state.skillMatchEnabled = bool
    },
    setCompanyId(state, companyId) {
      state.companyId = companyId
    },
    resetConditions(state) {
      state.checkedJobCategories.splice(0, state.checkedJobCategories.length)
      state.checkedSkills.splice(0, state.checkedSkills.length)
      state.checkedWorkStyles.splice(0, state.checkedWorkStyles.length)
      state.checkedLocations.splice(0, state.checkedLocations.length)
      Vue.set(state, "unitPriceMin", { name: "", code: null })
      Vue.set(state, "unitPriceMax", { name: "", code: null })
      state.checkedWorkingTime.splice(0, state.checkedWorkingTime.length)
      state.checkedFrequenciesOfGoingToOffice.splice(0, state.checkedFrequenciesOfGoingToOffice.length)
      state.checkedFeatures.splice(0, state.checkedFeatures.length)
      state.keyword = ""
      state.skillKeyword = ""
      state.onlyNewJobs = ""
      state.onlyHiringJobs = ""
      state.searchPreviewCount = null
      state.companyId = 0
    },
    setSearchResult(state, result) {

      console.log(result)
      state.searchResults = result.jobs
      state.searchResultCount = result.totalCount
      state.pageSize = result.perPage
      state.searchResultIndexFrom = result.firstIndex
      state.searchResultIndexTo = result.lastIndex
      state.searchPreviewCount = result.totalCount
      state.alternateResults = result.alternateResults
      state.companyId = result.companyId
      state.recommendedJobs = result.recommendedResults
      state.recommendTitle = result.recommendTitle
    },
    resetSearchResult(state) {
      state.searchResults = []
      state.searchResultCount = 0
      state.searchResultIndexFrom = 0
      state.searchResultIndexTo = 0
      state.alternateResults = []
      state.currentPage = 1
      state.companyId = 0
    },
    setInterestRecommendJobs(state, result) {
      state.interestRecommendJobs = result.jobs
    },
    setRecommendTitle(state, result) {
      state.recommendTitle = result.recommendTitle
    },
    setSearchPreviewCount(state, count) {
      state.searchPreviewCount = count
    },
    setCurrentPage(state, page) {
      state.currentPage = page
    },
    setSearchHistoryText(state, text) {
      if (text) {
        // 長さが1こ以上なら
        if (state.searchHistoryTexts.length >= 1 && state.searchHistoryTextsMypage.length >= 1) {
          // 同じものが含まれていた場合

          // トップページは直前と同じ条件だったら何もしない
          if (
            !(text === state.searchHistoryTexts[0])
          ) {
            state.searchHistoryTexts.push(text)
          }

          // マイページは過去3回前いないと同じ条件だったら更新しない
          if (
            state.searchHistoryTextsMypage.indexOf(text) == -1
          ) {
            if (state.searchHistoryTextsMypage.length >= 4) {
              state.searchHistoryTextsMypage.unshift(text)
              state.searchHistoryTextsMypage.splice(4)
            } else {
              state.searchHistoryTextsMypage.push(text)
            }
          }
        } else {
          state.searchHistoryTexts.push(text)
          state.searchHistoryTextsMypage.push(text)
        }
      }
    },
    setSearchHistoryPath(state, path) {
      if (path) {
        // 長さが1こ以上なら
        if (state.searchHistoryPaths.length >= 1 && state.searchHistoryPathsMypage.length >= 1) {
          // 同じものが含まれていた場合

          // トップページなどは直前と同じ条件だったら何もしない
          if (
            !(path === state.searchHistoryPaths[0])
          ) {
            state.searchHistoryPaths.push(path)
          }

          // マイページは過去3回前以内に同じ条件だったら更新しない
          if (
            state.searchHistoryPathsMypage.indexOf(path) == -1
          ) {
            if (state.searchHistoryPathsMypage.length >= 4) {
              state.searchHistoryPathsMypage.unshift(path)
              state.searchHistoryPathsMypage.splice(4)
            } else {
              state.searchHistoryPathsMypage.push(path)
            }
          }
        } else {
          state.searchHistoryPaths.push(path)
          state.searchHistoryPathsMypage.push(path)
        }
      }
    },
    setSearchConditionText(state, text) {
      state.searchConditionText = text
    },
    setBreadCrumbBeforeMiddle(state, textPathObj) {
      state.breadCrumbBeforeMiddle = textPathObj
    },
    setBreadCrumbMiddle(state, textPathObj) {
      state.breadCrumbMiddle = textPathObj
    },
    setBreadCrumbLast(state, textPathObj) {
      if (state.searchConditionText == '') {
        state.breadCrumbLast = { text: '業務委託・フリーランス・副業の案件情報', path: '' }
      } else {
        state.breadCrumbLast = { text: `${textPathObj.text}の案件情報`, path: textPathObj.path }
      }
    },
    setH1Text(state, text) {
      state.h1Text = text
    },
  },
  actions: {
    search({ commit, state, rootState }, options) {
      return new Promise((resolve) => {
        (async () => {
          const params = {
            jobCategories: [],
            skills: [],
            workStyles: [],
            locations: [],
            minUnitPrice: state.unitPriceMin,
            maxUnitPrice: state.unitPriceMax,
            workingTimes: [],
            frequenciesOfGoingToOffice: [],
            features: [],
            skillMatchEnabled: false,
            onlyNewJobs: false,
            onlyHiringJobs: false,
            keyword: null,
            skillKeyword: null,
            companyId: 0,
            page: 1,
            sort: 0,
            pageSize: 20,

          }

          const opt = options ? options : {}

          state.checkedJobCategories.forEach(code => {
            params.jobCategories.push(code)
          });
          state.checkedSkills.forEach(code => {
            params.skills.push(code)
          });
          state.checkedWorkStyles.forEach(code => {
            params.workStyles.push(code)
          });
          state.checkedLocations.forEach(code => {
            params.locations.push(code)
          });
          state.checkedLocations.forEach(code => {
            params.locations.push(code)
          });
          state.checkedWorkingTime.forEach(code => {
            params.workingTimes.push(code)
          });
          state.checkedFrequenciesOfGoingToOffice.forEach(code => {
            params.frequenciesOfGoingToOffice.push(code)
          });
          state.checkedFeatures.forEach(code => {
            params.features.push(code)
          });
          if (state.keyword.length > 0) {
            params.keyword = state.keyword
          }
          if (state.skillKeyword.length > 0) {
            params.skillKeyword = state.skillKeyword
          }
          params.companyId = state.companyId
          params.skillMatchEnabled = state.skillMatchEnabled
          params.onlyNewJobs = state.onlyNewJobs
          params.onlyHiringJobs = state.onlyHiringJobs
          params.page = state.currentPage
          params.sort = state.sort.code
          params.preview = opt.preview ? true : false
          params.pageSize = state.pageSize

          let result;

          if (rootState.auth.isLoggedIn) {
            result = await MemberAPI.search(params).catch(e => {
              console.log(e)
              return false;
            })
          }
          else {
            result = await PublicAPI.search(params).catch(e => {
              console.log(e)
              return false;
            })
          }

          if (!result) {
            return false;
          }

          if (opt.preview) {
            commit('setSearchPreviewCount', result.totalCount)
          }
          else if (opt.showResults) {
            commit('setSearchResult', result)
          }

          resolve()
        })();
      });
    },
    getInterestRecommendJobs({commit, rootState}) {
      return new Promise((resolve) => {

          let result;

          if (rootState.auth.isLoggedIn) {
            MemberAPI.searchInterestRecommendJobs()
            .then((result) => {
              commit('setInterestRecommendJobs', result)
              commit('setRecommendTitle', result)
            })
            .catch(e => {
              console.log(e)
              return false;
            })
          } else {
            PublicAPI.searchInterestRecommendJobs()
            .then((result) => {
              commit('setInterestRecommendJobs', result)
              commit('setRecommendTitle', result)
            })
            .catch(e => {
              console.log(e)
              return false;
            })
          }

          if (!result) {
            return false;
          }
        resolve()
      })
    },
    setConditionsFromProfile({ commit, rootState }) {

      const jobCategories = []
      rootState.profile.jobCategories.forEach((s) => {
        jobCategories.push(s)
      })

      const skills = []
      rootState.profile.professionalLevelSkills.forEach((s) => {
        skills.push(s)
      })
      rootState.profile.businessLevelSkills.forEach((s) => {
        skills.push(s)
      })
      rootState.profile.basicLevelSkills.forEach((s) => {
        skills.push(s)
      })

      commit('setCheckedJobCategories', jobCategories)
      commit('setCheckedSkills', skills)
      commit('setCheckedLocations', rootState.profile.locations)
      commit('setUnitPriceMin', rootState.profile.unitPriceMin)
      commit('setUnitPriceMax', rootState.profile.unitPriceMax)
      commit('setCheckedWorkStyles', rootState.profile.workStyles)
      commit('setCheckedWorkingTime', rootState.profile.workingTimes)
    },
    setConditionsByRoute({ commit, rootState }, route) {
      return new Promise((resolve) => {
        commit('resetConditions')

        const jobCategories = []
        const locations = []
        const workingTime = []
        const features = []
        const workStyles = []
        const frequeniesOfGoingToOffice = []
        const skillCodes = []

        Object.keys(route.params).forEach((key) => {
          const pathParameter = route.params[key]
          const params = pathParameter.split(/\+/)

          console.log('rootState.constants.workingTimes', rootState.constants.workingTimes)

          params.forEach((param) => {
            if (param.startsWith('J')) {
              const code = CodeHelper.getCodeObject(rootState.constants.jobCategories, param);
              if (code) jobCategories.push(code)
            }
            else if (param.startsWith('A') || param.startsWith('P')) {
              const code = CodeHelper.getCodeObject(rootState.constants.locations, param)
              if (code) locations.push(code)
            }
            else if (param.startsWith('H')) {
              const code = CodeHelper.getCodeObject(rootState.constants.workingTimes, param)
              // if (code) {
              //   // MYF_2-967 検索条件とパンくずだけ「週」を付けたい
              //   let formatCode = Object.assign({}, code);
              //   formatCode.name = '週' + formatCode.name;
              //   workingTime.push(formatCode)
              // } 
              if (code) workingTime.push(code)
            }
            else if (param.startsWith('F')) {
              const code = CodeHelper.getCodeObject(rootState.constants.features, param)
              if (code) features.push(code)
            }
            else if (param.startsWith('W')) {
              const code = CodeHelper.getCodeObject(rootState.constants.workStyles, param)
              if (code) workStyles.push(code)
            }
            else if (param.startsWith('C')) {
              const code = CodeHelper.getCodeObject(rootState.constants.frequeniesOfGoingToOffice, param)
              if (code) frequeniesOfGoingToOffice.push(code)
            }
            else if (param.startsWith('RG')) {
              const code = CodeHelper.getCodeObject(rootState.constants.minUnitPrices, param)
              if (code) commit('setUnitPriceMin', code)
            }
            else if (param.startsWith('RL')) {
              const code = CodeHelper.getCodeObject(rootState.constants.maxUnitPrices, param)
              if (code) commit('setUnitPriceMax', code)
            }
            else if (param.startsWith('S')) {
              skillCodes.push(param)
            }
          })
        })

        commit('setCheckedJobCategories', jobCategories)
        commit('setCheckedLocations', locations)
        commit('setCheckedWorkingTime', workingTime)
        commit('setCheckedFeatures', features)
        commit('setCheckedWorkStyles', workStyles)
        commit('setCheckedFrequenciesOfGoingToOffice', frequeniesOfGoingToOffice)

        Object.keys(route.query).forEach((name) => {
          if (name == 'fq') commit('setKeyword', route.query[name])
          else if (name == 'sq') commit('setSkillKeyword', route.query[name])
          else if (name == 's') {
            if (route.query[name]) {
              const code = CodeHelper.getCodeObject(rootState.constants.jobSortKeys, route.query[name])
              if (code) commit('setSort', code)
            }
          }
          else if (name == 'n') {
            commit('setOnlyNewJobs', route.query[name] == 'true')
          }
          else if (name== 'h') {
            commit('setOnlyHiringJobs', route.query[name] == 'true')
          }
          else if (name == 'sm') {
            commit('setSkillMatchEnabled', route.query[name] == 'true')
          }
          else if (name == 'pg') {
            if (route.query[name].match(/^\d+$/)) {
              if (route.query[name]) commit('setCurrentPage', route.query[name] - 0)
            }
          }
        });

        if (skillCodes.length > 0) {
          (async () => {
            const result = await PublicAPI.getConstantsSkills({
              code: skillCodes
            }).catch(e => {
              console.log(e)
              return false;
            })

            if (!result) {
              return false;
            }
            commit('setCheckedSkills', result.skills)
            resolve()
          })()
        }
        else {
          resolve()
        }
      });
    },
    setSearchHistories({ commit }) {
      for (var i = 0; i < 4; i++) {
        var searchHistoryText = localStorage.getItem(`previousSearchConditionText${i == 0 ? '' : i + 1}`)
        var searchHistoryPath = localStorage.getItem(`previousSearchPath${i == 0 ? '' : i + 1}`)

        commit('setSearchHistoryText', searchHistoryText)
        commit('setSearchHistoryPath', searchHistoryPath)
      }
    },
    // 仕事カテゴリと都道府県のstateを整理する
    sumCategory({ state, commit, getters, rootState }, checkedCategories) {
      // 判定、実行速度向上のためにcheckedCategoriesを使用
      if (checkedCategories && checkedCategories.length > 0) {

        // 仕事カテゴリ、都道府県を分ける
        if (
          checkedCategories[0].code.indexOf("JS") != -1 ||
          checkedCategories[0].code.indexOf("JL") != -1
        ) {
          // 大カテゴリに合算される時にcheckedJobCategoriesを更新する
          // 大カテゴリの分だけ回す
          for (var i = 0; i < 10; i++) {
            var sortedJobs = checkedCategories.filter((d) => {
              return d.code.indexOf(`JS0${i}`) != -1;
            });
            // カテゴリを選択していたら
            if (sortedJobs && sortedJobs.length > 0) {
              // 親obj
              var parentObj = CodeHelper.getTopLevelObject(
                rootState.constants.jobCategories,
                sortedJobs[0].code
              )
              // 子コード達が親コードと同一だったら
              if (
                getters.isSameAsParentCode(
                  parentObj,
                  sortedJobs
                )
              ) {
                // sortedJobsの内容（親コードのchildren）をstate.checkedJobCategoriesから抜く
                var newArray = []
                newArray = state.checkedJobCategories.filter((job) => {
                  return !parentObj.children.includes(job)
                })

                var parentJobs = checkedCategories.filter((d) => {
                  return d.code == parentObj.code
                })

                // 既に親コードが入っていなかったら追加する
                if (!(parentJobs && parentJobs.length > 0)) {
                  // state.checkedJobCategoriesの最後に親objを入れる
                  newArray.push(parentObj)
                }

                commit('setCheckedJobCategories', newArray)
              } else {
                // 親コードも子コードもあるけど親コードにならなかった場合
                commit('setCheckedJobCategories', state.checkedJobCategories.filter((d) => {
                  return d.code != parentObj.code
                }))
              }
            } else {
              // その他技術者の対応
              var otherEngineerParentObj = CodeHelper.getTopLevelObject(
                rootState.constants.jobCategories,
                'JS9901'
              )
              for (var j = 0; j < checkedCategories.length; j++) {
                if (checkedCategories[j].code == 'JS9901') {
                  // その他技術者の親子コードを省く
                  checkedCategories = checkedCategories.filter((e) => {
                    return e.code != checkedCategories[j].code
                  })
                  checkedCategories = checkedCategories.filter((e) => {
                    return e.code != otherEngineerParentObj.code
                  })

                  // 親コードだけ格納
                  checkedCategories.push(otherEngineerParentObj)

                  commit('setCheckedJobCategories', checkedCategories)
                } else {
                  // 小コードがなく、親コードのみの場合
                  // 全て小コードが入ってくるから基本的にここには入らないはず
                  console.log('その他技術者以外の時')
                }
              }
            }
          }

          // 都道府県
        } else {
          // 全国じゃない場合、dataの都道府県を大分類別に分ける…sortedPref
          // 北海道
          var a1 = checkedCategories.filter((p) => {
            return p.code === 'P1'
          })
          // 東北
          var a2 = checkedCategories.filter((p) => {
            return p.code === "P2" || p.code === "P3" || p.code === "P4" || p.code === "P5" || p.code === "P6" || p.code === "P7"
          })
          // 北関東
          var a3 = checkedCategories.filter((p) => {
            return p.code === "P8" || p.code === "P9" || p.code === "P10"
          })
          // 首都圏
          var a4 = checkedCategories.filter((p) => {
            return p.code === "P11" || p.code === "P12" || p.code === "P13" || p.code === "P14"
          })
          // 中部
          var a5 = checkedCategories.filter((p) => {
            return p.code === "P15" || p.code === "P16" || p.code === "P17" || p.code === "P18" || p.code === "P19" || p.code === "P20"
          })
          // 東海
          var a6 = checkedCategories.filter((p) => {
            return p.code === "P21" || p.code === "P22" || p.code === "P23" || p.code === "P24"
          })
          // 関西
          var a7 = checkedCategories.filter((p) => {
            return p.code === "P25" || p.code === "P26" || p.code === "P27" || p.code === "P28" || p.code === "P29" || p.code === "P30"
          })
          // 中国・四国
          var a8 = checkedCategories.filter((p) => {
            return p.code === "P31" || p.code === "P32" || p.code === "P33" || p.code === "P34" || p.code === "P35" || p.code === "P36" || p.code === "P37" || p.code === "P38" || p.code === "P39"
          })
          // 九州・沖縄
          var a9 = checkedCategories.filter((p) => {
            return p.code === "P40" || p.code === "P41" || p.code === "P42" || p.code === "P43" || p.code === "P44" || p.code === "P45" || p.code === "P46" || p.code === "P47"
          })

          // 大分類で回して整理する
          for (var k = 0; k <= rootState.constants.locations.length; k++) {
            switch (k) {
              case 1:  // 北海道
                if (a1 && a1.length > 0) {
                  getters.cleanPrefCategory(rootState.constants.locations[k - 1], a1, checkedCategories)
                }
                break
              case 2:  // 東北
                if (a2 && a2.length > 0) {
                  getters.cleanPrefCategory(rootState.constants.locations[k - 1], a2, checkedCategories)
                }
                break
              case 3:  // 北関東
                if (a3 && a3.length > 0) {
                  getters.cleanPrefCategory(rootState.constants.locations[k - 1], a3, checkedCategories)
                }
                break
              case 4:  // 首都圏
                if (a4 && a4.length > 0) {
                  getters.cleanPrefCategory(rootState.constants.locations[k - 1], a4, checkedCategories)
                }
                break
              case 5:  // 中部
                if (a5 && a5.length > 0) {
                  getters.cleanPrefCategory(rootState.constants.locations[k - 1], a5, checkedCategories)
                }
                break
              case 6:  // 東海
                if (a6 && a6.length > 0) {
                  getters.cleanPrefCategory(rootState.constants.locations[k - 1], a6, checkedCategories)
                }
                break
              case 7:  // 関西
                if (a7 && a7.length > 0) {
                  getters.cleanPrefCategory(rootState.constants.locations[k - 1], a7, checkedCategories)
                }
                break
              case 8:  // 中国・四国
                if (a8 && a8.length > 0) {
                  getters.cleanPrefCategory(rootState.constants.locations[k - 1], a8, checkedCategories)
                }
                break
              case 9:  // 九州・沖縄
                if (a9 && a9.length > 0) {
                  getters.cleanPrefCategory(rootState.constants.locations[k - 1], a9, checkedCategories)
                }
                break
              default:
                break
            }
          }
        }
      }
    },
    // パンクズりすと
    setBreadCrumbs({ state, commit, getters, rootState }) {
      // 末端パンクずは現在の検索条件と同じ
      commit("setBreadCrumbLast", { text: state.searchConditionText, path: state.searchConditionCode })
      // // 仕様書から参照（21/6/3時点）
      // ルール①：末端ページURLの一番左側要素が、唯一の要素、または要素がない場合は、中間ページはトルツメ ※セルフページリンクのみ
      // ルール②：末端ページURLの一番左側（/jobの右隣）に指定されている要素のURLを中間ページにする
      // ルール③：末端ページURLの一番左側要素が、要素内に複数のコードが指定されている場合は無視し、その右隣の要素のURLを中間ページにする

      // 中間パンクズ
      // 中間パンクズをクリア
      commit("setBreadCrumbBeforeMiddle", { text: '', path: '' })
      commit("setBreadCrumbMiddle", { text: '', path: '' })

      var beforeMiddleBreadCrumbText = ''
      var beforeMiddleBreadCrumbPath = ''
      var middleBreadCrumbText = []
      var middleBreadCrumbPath = []

      // 中間パンクズの優先順位がある。
      if (state.checkedJobCategories && state.checkedJobCategories.length == 1) {
        // 仕事カテゴリ

        // MYF_2-967 【SEO】パンくず改修
        // 中項目が検索条件の場合、上層に上位項目を追加する。
        if(state.checkedJobCategories[0].code.indexOf("JS") != -1) {

          // 親カテゴリのパンくず生成
          var parentJobCategory = CodeHelper.getTopLevelObject(
            rootState.constants.jobCategories,
            state.checkedJobCategories[0].code
          )

          beforeMiddleBreadCrumbText = parentJobCategory.name
          beforeMiddleBreadCrumbPath = parentJobCategory.code

        }

        // 検索条件がこれのみの場合は、3階層と重複するのでここでは生成しない。
        if( state.searchConditionCount != 1 ) {

          getters.setSearchConditionTexts(middleBreadCrumbText, state.checkedJobCategories)
          getters.setSearchConditionCodes(middleBreadCrumbPath, state.checkedJobCategories)
        }

      } else if (state.checkedLocations && state.checkedLocations.length == 1) {
        // 勤務地

        // MYF_2-967 【SEO】パンくず改修
        // 中項目が検索条件の場合、上層に上位項目を追加する。
        if(state.checkedLocations[0].code.indexOf("P") != -1) {

          // 親カテゴリのパンくず生成
          var parentArea = CodeHelper.getTopLevelObject(
            rootState.constants.locations,
            state.checkedLocations[0].code
          )

          beforeMiddleBreadCrumbText = parentArea.name
          beforeMiddleBreadCrumbPath = parentArea.code

        }
        // 検索条件がこれのみの場合は、3階層と重複するのでここでは生成しない。
        if( state.searchConditionCount != 1 ) {

          getters.setSearchConditionTexts(middleBreadCrumbText, state.checkedLocations)
          getters.setSearchConditionCodes(middleBreadCrumbPath, state.checkedLocations)
        }
      } else if (state.checkedWorkStyles && state.checkedWorkStyles.length == 1) {
        // 働き方
        if( state.searchConditionCount != 1 ) {

          getters.setSearchConditionTexts(middleBreadCrumbText, state.checkedWorkStyles)
          getters.setSearchConditionCodes(middleBreadCrumbPath, state.checkedWorkStyles)
        }
      } else if (state.checkedFrequenciesOfGoingToOffice && state.checkedFrequenciesOfGoingToOffice.length == 1) {
        // 出社頻度
        if( state.searchConditionCount != 1 ) {

          getters.setSearchConditionTexts(middleBreadCrumbText, state.checkedFrequenciesOfGoingToOffice)
          getters.setSearchConditionCodes(middleBreadCrumbPath, state.checkedFrequenciesOfGoingToOffice)
        }
      } else if (state.checkedFeatures && state.checkedFeatures.length == 1) {
        // 特徴
        if( state.searchConditionCount != 1 ) {

          getters.setSearchConditionTexts(middleBreadCrumbText, state.checkedFeatures)
          getters.setSearchConditionCodes(middleBreadCrumbPath, state.checkedFeatures)
        }
        // } else if (state.unitPriceMin && state.unitPriceMin.code) {
        //   // 想定報酬〜円以上
        //   getters.setSearchConditionTexts(middleBreadCrumbText, [{ code: state.unitPriceMin.code, name: `${state.unitPriceMin.name}円以上` }])
        //   getters.setSearchConditionCodes(middleBreadCrumbPath, [state.unitPriceMin])
        // } else if (state.unitPriceMax && state.unitPriceMax.code) {
        //   // 想定報酬〜円以下
        //   getters.setSearchConditionTexts(middleBreadCrumbText, [{ code: state.unitPriceMax.code, name: `${state.unitPriceMax.name}円以下` }])
        //   getters.setSearchConditionCodes(middleBreadCrumbPath, [state.unitPriceMax])
      } else if (state.checkedSkills && state.checkedSkills.length == 1) {
        // スキル
        getters.setSearchConditionTexts(middleBreadCrumbText, state.checkedSkills)
        getters.setSearchConditionCodes(middleBreadCrumbPath, state.checkedSkills)
      } else if (state.keyword) {
        // フリーキーワード
        middleBreadCrumbText.push(state.keyword)
        middleBreadCrumbPath.push('fq=' + encodeURIComponent(state.keyword))
      }
      
      if (beforeMiddleBreadCrumbText != '' && beforeMiddleBreadCrumbPath != '') {
        commit("setBreadCrumbBeforeMiddle", { text: beforeMiddleBreadCrumbText, path: `/job/${beforeMiddleBreadCrumbPath}` })
      }

      if (middleBreadCrumbText.length != 0 && middleBreadCrumbPath.length != 0) {
        if (state.breadCrumbLast.text != middleBreadCrumbText[0]) {
          commit("setBreadCrumbMiddle", { text: middleBreadCrumbText[0], path: `/job/${middleBreadCrumbPath[0]}` })
        }
      }

    },
    createH1Text({ state, commit }) {
      var text = ''
      if (state.searchConditionText != '') {
        text = `${state.searchConditionText}の案件情報`
      } else {
        // 条件指定がない時
        text = '業務委託・フリーランス・副業の案件情報'
      }
      commit("setH1Text", text)
    }
  },
  getters: {
    path: (state, getters) => {
      const parts = ['job']
      const queries = []
      let searchConditionCount = 0;

      getters.setSearchConditionCodes(parts, state.checkedJobCategories);

      getters.setSearchConditionCodes(parts, state.checkedLocations);

      getters.setSearchConditionCodes(parts, state.checkedWorkingTime);

      getters.setSearchConditionCodes(parts, state.checkedFeatures)

      getters.setSearchConditionCodes(parts, state.checkedWorkStyles)

      getters.setSearchConditionCodes(parts, state.checkedFrequenciesOfGoingToOffice)

      if (state.unitPriceMin && state.unitPriceMin.code) {
        parts.push(state.unitPriceMin.code)
      }

      if (state.unitPriceMax && state.unitPriceMax.code) {
        parts.push(state.unitPriceMax.code)
      }

      getters.setSearchConditionCodes(parts, state.checkedSkills)

      if (state.skillKeyword) {
        queries.push('sq=' + encodeURIComponent(state.skillKeyword))
      }

      if (state.keyword) {
        queries.push('fq=' + encodeURIComponent(state.keyword))
        searchConditionCount++
      }

      if (state.onlyNewJobs) {
        queries.push('n=true')
        searchConditionCount++
      }

      if (state.onlyHiringJobs) {
        queries.push('h=true')
        searchConditionCount++
      }

      if (state.skillMatchEnabled) {
        queries.push('sm=true')
      }

      if (state.sort.code != '01') {
        queries.push('s=' + state.sort.code)
      }

      if (state.currentPage > 1) {
        queries.push('pg=' + state.currentPage)
      }

      var path = '/' + parts.join('/') + (queries.length > 0 ? ('?' + queries.join('&')) : '')

      state.searchConditionCode = path

      searchConditionCount += parts.length - 1;

      state.searchConditionCount = searchConditionCount

      return path
    },
    // 検索関係のpath生成getters
    setSearchConditionCodes: (state, getters, rootState) => (parts, data) => {
      if (data && data.length > 0) {
        var conditionCodes = [];

        // 仕事カテゴリ、都道府県とその他の分岐
        if (
          data[0].code.indexOf("JS") != -1 ||
          data[0].code.indexOf("JL") != -1 ||
          data[0].code.indexOf("P") != -1
        ) {
          // 仕事カテゴリ
          if (
            data[0].code.indexOf("JS") != -1 ||
            data[0].code.indexOf("JL") != -1
          ) {

            // 親コードが入っていた場合
            var sortedParentJobs = data.filter((d) => {
              return rootState.constants.jobCategories.includes(d)
            })
            if (sortedParentJobs && sortedParentJobs.length > 0) {
              sortedParentJobs.forEach((code) => {
                conditionCodes.push(code.code);
              });
            }

            // カテゴリごとに分割処理してconditionTextに入れる
            // 大カテゴリの分だけ回す
            for (var i = 0; i < 10; i++) {
              var sortedJobs = data.filter((d) => {
                return d.code.indexOf(`JS0${i}`) != -1;
              });
              // カテゴリを選択していたら
              if (sortedJobs && sortedJobs.length > 0) {
                // 子コード達が親コードと同一だったら
                if (
                  getters.isSameAsParentCode(
                    CodeHelper.getTopLevelObject(
                      rootState.constants.jobCategories,
                      sortedJobs[0].code
                    ),
                    sortedJobs
                  )
                ) {
                  var parentJobs = data.filter((d) => {
                    return d.code.indexOf(
                      CodeHelper.getTopLevelObject(
                        rootState.constants.jobCategories,
                        sortedJobs[0].code
                      ).code
                    ) != -1
                  })
                  // 親コードが既に入っていなかったら入れる
                  if (!(parentJobs && parentJobs.length > 0)) {
                    conditionCodes.push(
                      getters.createParentCategoryCode(
                        CodeHelper.getTopLevelObject(
                          rootState.constants.jobCategories,
                          sortedJobs[0].code
                        ),
                        sortedJobs
                      )
                    );
                  }
                } else {
                  // 小コードたちが親コードとならない時
                  // 普通にconditionCodesに加える
                  sortedJobs.forEach((code) => {
                    conditionCodes.push(code.code);
                  });
                }
              }
            }

            // 「その他技術職」カテゴリへの対応
            if (data[0].code.indexOf("JS9901") != -1) {
              conditionCodes.push('JL99');
            }

            // 都道府県
          } else {
            // dataの都道府県が47こ全て選択されていたら全国をconditionCodesに格納する
            if (data.length === 47) {
              console.log('全国検索のため何も格納しない')
            } else {
              // 全国じゃない場合、dataの都道府県を大分類別に分ける…sortedPref
              // 北海道
              var a1 = data.filter((p) => {
                return p.code === 'P1'
              })
              // 東北
              var a2 = data.filter((p) => {
                return p.code === "P2" || p.code === "P3" || p.code === "P4" || p.code === "P5" || p.code === "P6" || p.code === "P7"
              })
              // 北関東
              var a3 = data.filter((p) => {
                return p.code === "P8" || p.code === "P9" || p.code === "P10"
              })
              // 首都圏
              var a4 = data.filter((p) => {
                return p.code === "P11" || p.code === "P12" || p.code === "P13" || p.code === "P14"
              })
              // 中部
              var a5 = data.filter((p) => {
                return p.code === "P15" || p.code === "P16" || p.code === "P17" || p.code === "P18" || p.code === "P19" || p.code === "P20"
              })
              // 東海
              var a6 = data.filter((p) => {
                return p.code === "P21" || p.code === "P22" || p.code === "P23" || p.code === "P24"
              })
              // 関西
              var a7 = data.filter((p) => {
                return p.code === "P25" || p.code === "P26" || p.code === "P27" || p.code === "P28" || p.code === "P29" || p.code === "P30"
              })
              // 中国・四国
              var a8 = data.filter((p) => {
                return p.code === "P31" || p.code === "P32" || p.code === "P33" || p.code === "P34" || p.code === "P35" || p.code === "P36" || p.code === "P37" || p.code === "P38" || p.code === "P39"
              })
              // 九州・沖縄
              var a9 = data.filter((p) => {
                return p.code === "P40" || p.code === "P41" || p.code === "P42" || p.code === "P43" || p.code === "P44" || p.code === "P45" || p.code === "P46" || p.code === "P47"
              })

              let a1SmallCount = 0;
              let a2SmallCount = 0;
              var a3SmallCount = 0;
              var a4SmallCount = 0;
              var a5SmallCount = 0;
              var a6SmallCount = 0;
              var a7SmallCount = 0;
              var a8SmallCount = 0;
              var a9SmallCount = 0;

              // 親コードが入っていた場合
              var sortedArea = data.filter((d) => {
                return rootState.constants.locations.includes(d)
              })
              if (sortedArea && sortedArea.length > 0) {
                sortedArea.forEach((code) => {
                  conditionCodes.push(code.code);
                });
              }

              // 大分類で回す
              for (var k = 0; k <= rootState.constants.locations.length; k++) {
                switch (k) {
                  case 1:  // 北海道
                    if (a1 && a1.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a1.length) {
                        conditionCodes.push(rootState.constants.locations[k - 1].code)
                      } else {
                        a1SmallCount += 1;
                      }
                    }
                    break
                  case 2:  // 東北
                    if (a2 && a2.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a2.length) {
                        conditionCodes.push(rootState.constants.locations[k - 1].code)
                      } else {
                        a2SmallCount += 1;
                      }
                    }
                    break
                  case 3:  // 北関東
                    if (a3 && a3.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a3.length) {
                        conditionCodes.push(rootState.constants.locations[k - 1].code)
                      } else {
                        a3SmallCount += 1;
                      }
                    }
                    break
                  case 4:  // 首都圏
                    if (a4 && a4.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a4.length) {
                        conditionCodes.push(rootState.constants.locations[k - 1].code)
                      } else {
                        a4SmallCount += 1;
                      }
                    }
                    break
                  case 5:  // 中部
                    if (a5 && a5.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a5.length) {
                        conditionCodes.push(rootState.constants.locations[k - 1].code)
                      } else {
                        a5SmallCount += 1;
                      }
                    }
                    break
                  case 6:  // 東海
                    if (a6 && a6.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a6.length) {
                        conditionCodes.push(rootState.constants.locations[k - 1].code)
                      } else {
                        a6SmallCount += 1;
                      }
                    }
                    break
                  case 7:  // 関西
                    if (a7 && a7.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a7.length) {
                        conditionCodes.push(rootState.constants.locations[k - 1].code)
                      } else {
                        a7SmallCount += 1;
                      }
                    }
                    break
                  case 8:  // 中国・四国
                    if (a8 && a8.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a8.length) {
                        conditionCodes.push(rootState.constants.locations[k - 1].code)
                      } else {
                        a8SmallCount += 1;
                      }
                    }
                    break
                  case 9:  // 九州・沖縄
                    if (a9 && a9.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a9.length) {
                        conditionCodes.push(rootState.constants.locations[k - 1].code)
                      } else {
                        a9SmallCount += 1;
                      }
                    }
                    break
                  default:
                    break
                }
              }

              // 小分類を大分類を入れた後から入れる
              if (a1SmallCount != 0) {
                a1.forEach((obj) => {
                  conditionCodes.push(obj.code);
                });
              }
              if (a2SmallCount != 0) {
                a2.forEach((obj) => {
                  conditionCodes.push(obj.code);
                });
              }
              if (a3SmallCount != 0) {
                a3.forEach((obj) => {
                  conditionCodes.push(obj.code);
                });
              }
              if (a4SmallCount != 0) {
                a4.forEach((obj) => {
                  conditionCodes.push(obj.code);
                });
              }
              if (a5SmallCount != 0) {
                a5.forEach((obj) => {
                  conditionCodes.push(obj.code);
                });
              }
              if (a6SmallCount != 0) {
                a6.forEach((obj) => {
                  conditionCodes.push(obj.code);
                });
              }
              if (a7SmallCount != 0) {
                a7.forEach((obj) => {
                  conditionCodes.push(obj.code);
                });
              }
              if (a8SmallCount != 0) {
                a8.forEach((obj) => {
                  conditionCodes.push(obj.code);
                });
              }
              if (a9SmallCount != 0) {
                a9.forEach((obj) => {
                  conditionCodes.push(obj.code);
                });
              }

            }
          }
        } else {
          // その他の検索条件の時
          data.forEach((code) => {
            conditionCodes.push(code.code);
          });
        }

        // partsに格納する
        if (conditionCodes && conditionCodes.length > 0) {
          parts.push(conditionCodes.join('+'))
        }
      }
    },
    createParentCategoryCode: () => (parentObject, childrenArray) => {
      if (childrenArray && childrenArray.length > 0) {
        return parentObject.code;
      } else {
        return null;
      }
    },

    // 検索関係のテキスト生成getters
    conditionText: (state, getters) => {
      const conditionTexts = [];
      getters.setSearchConditionTexts(conditionTexts, state.checkedJobCategories);

      getters.setSearchConditionTexts(conditionTexts, state.checkedSkills);

      getters.setSearchConditionTexts(conditionTexts, state.checkedLocations);

      if ( state.unitPriceMin && state.unitPriceMin.code) {
        conditionTexts.push(`時給${state.unitPriceMin.name}円以上`);
      }
      if ( state.unitPriceMin && state.unitPriceMax.code) {
        conditionTexts.push(`時給${state.unitPriceMax.name}円以下`);
      }

      getters.setSearchConditionTexts(conditionTexts, state.checkedWorkStyles);

      getters.setSearchConditionTexts(conditionTexts, state.checkedWorkingTime);

      getters.setSearchConditionTexts(
        conditionTexts,
        state.checkedFrequenciesOfGoingToOffice
      );

      getters.setSearchConditionTexts(conditionTexts, state.checkedFeatures);

      if (state.onlyNewJobs) {
        conditionTexts.push("新着のみ");
      }
      if (state.onlyHiringJobs) {
        conditionTexts.push("募集中のみ");
      }
      if (state.keyword) {
        conditionTexts.push(state.keyword);
      }

      state.searchConditionText = conditionTexts.join(" / ")
      return state.searchConditionText
    },
    setSearchConditionTexts: (state, getters, rootState) => (searchConditionTexts, data) => {
      if (data && data.length > 0) {
        var conditionTexts = [];

        // 仕事カテゴリ、都道府県とその他の分岐
        if (
          data[0].code.indexOf("JS") != -1 ||
          data[0].code.indexOf("JL") != -1 ||
          data[0].code.indexOf("P") != -1 || 
          data[0].code.indexOf("H") != -1
        ) {
          // 仕事カテゴリ
          if (
            data[0].code.indexOf("JS") != -1 ||
            data[0].code.indexOf("JL") != -1
          ) {

            // 親コードが入っていた場合
            var sortedParentJobs = data.filter((d) => {
              return rootState.constants.jobCategories.includes(d)
            })
            if (sortedParentJobs && sortedParentJobs.length > 0) {
              sortedParentJobs.forEach((code) => {
                conditionTexts.push(code.name);
              });
            }

            // カテゴリごとに分割処理してconditionTextに入れる
            // 大カテゴリの分だけ回す
            for (var i = 0; i < 10; i++) {
              // 小コードの場合
              var sortedJobs = data.filter((d) => {
                return d.code.indexOf(`JS0${i}`) != -1;
              });
              // カテゴリを選択していたら
              if (sortedJobs && sortedJobs.length > 0) {
                // 子コード達が親コードと同一だったら
                if (
                  getters.isSameAsParentCode(
                    CodeHelper.getTopLevelObject(
                      rootState.constants.jobCategories,
                      sortedJobs[0].code
                    ),
                    sortedJobs
                  )
                ) {
                  conditionTexts.push(
                    getters.createParentCategoryText(
                      CodeHelper.getTopLevelObject(
                        rootState.constants.jobCategories,
                        sortedJobs[0].code
                      ),
                      sortedJobs
                    )
                  );
                } else {
                  // 小コードたちが親コードとならない時
                  // 普通にconditionTextsに加える
                  sortedJobs.forEach((code) => {
                    conditionTexts.push(code.name);
                  });
                }
              }
            }

            // 「その他技術職」カテゴリへの対応
            if (data[0].code.indexOf("JS99") != -1) {
              conditionTexts.push(data[0].name);
            }
            // 都道府県
          } else if(data[0].code.indexOf("P") != -1) {
            // dataの都道府県が47こ全て選択されていたら全国をconditionTextsに格納する
            if (data.length === 47) {
              conditionTexts.push("全国")
            } else {
              // 全国じゃない場合、dataの都道府県を大分類別に分ける…sortedPref
              // 北海道
              var a1 = data.filter((p) => {
                return p.code === 'P1'
              })
              // 東北
              var a2 = data.filter((p) => {
                return p.code === "P2" || p.code === "P3" || p.code === "P4" || p.code === "P5" || p.code === "P6" || p.code === "P7"
              })
              // 北関東
              var a3 = data.filter((p) => {
                return p.code === "P8" || p.code === "P9" || p.code === "P10"
              })
              // 首都圏
              var a4 = data.filter((p) => {
                return p.code === "P11" || p.code === "P12" || p.code === "P13" || p.code === "P14"
              })
              // 中部
              var a5 = data.filter((p) => {
                return p.code === "P15" || p.code === "P16" || p.code === "P17" || p.code === "P18" || p.code === "P19" || p.code === "P20"
              })
              // 東海
              var a6 = data.filter((p) => {
                return p.code === "P21" || p.code === "P22" || p.code === "P23" || p.code === "P24"
              })
              // 関西
              var a7 = data.filter((p) => {
                return p.code === "P25" || p.code === "P26" || p.code === "P27" || p.code === "P28" || p.code === "P29" || p.code === "P30"
              })
              // 中国・四国
              var a8 = data.filter((p) => {
                return p.code === "P31" || p.code === "P32" || p.code === "P33" || p.code === "P34" || p.code === "P35" || p.code === "P36" || p.code === "P37" || p.code === "P38" || p.code === "P39"
              })
              // 九州・沖縄
              var a9 = data.filter((p) => {
                return p.code === "P40" || p.code === "P41" || p.code === "P42" || p.code === "P43" || p.code === "P44" || p.code === "P45" || p.code === "P46" || p.code === "P47"
              })

              let a1SmallCount = 0;
              let a2SmallCount = 0;
              var a3SmallCount = 0;
              var a4SmallCount = 0;
              var a5SmallCount = 0;
              var a6SmallCount = 0;
              var a7SmallCount = 0;
              var a8SmallCount = 0;
              var a9SmallCount = 0;

              // 親コードが入っていた場合
              var sortedArea = data.filter((d) => {
                return rootState.constants.locations.includes(d)
              })
              if (sortedArea && sortedArea.length > 0) {
                sortedArea.forEach((code) => {
                  conditionTexts.push(code.name);
                });
              }
              // 大分類で回す
              for (var k = 0; k <= rootState.constants.locations.length; k++) {
                switch (k) {
                  case 1:  // 北海道
                    if (a1 && a1.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a1.length) {
                        conditionTexts.push(rootState.constants.locations[k - 1].name)
                      } else {
                        a1SmallCount += 1;
                        // a1.forEach((obj) => {
                        //   conditionTexts.push(obj.name);
                        // });
                      }
                    }
                    break
                  case 2:  // 東北
                    if (a2 && a2.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a2.length) {
                        conditionTexts.push(rootState.constants.locations[k - 1].name)
                      } else {
                        a2SmallCount += 1;
                        // a2.forEach((obj) => {
                        //   conditionTexts.push(obj.name);
                        // });
                      }
                    }
                    break
                  case 3:  // 北関東
                    if (a3 && a3.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a3.length) {
                        conditionTexts.push(rootState.constants.locations[k - 1].name)
                      } else {
                        a3SmallCount += 1;
                        // a3.forEach((obj) => {
                        //   conditionTexts.push(obj.name);
                        // });
                      }
                    }
                    break
                  case 4:  // 首都圏
                    if (a4 && a4.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a4.length) {
                        conditionTexts.push(rootState.constants.locations[k - 1].name)
                      } else {
                        a4SmallCount += 1;
                        // a4.forEach((obj) => {
                        //   conditionTexts.push(obj.name);
                        // });
                      }
                    }
                    break
                  case 5:  // 中部
                    if (a5 && a5.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a5.length) {
                        conditionTexts.push(rootState.constants.locations[k - 1].name)
                      } else {
                        a5SmallCount += 1;
                        // a5.forEach((obj) => {
                        //   conditionTexts.push(obj.name);
                        // });
                      }
                    }
                    break
                  case 6:  // 東海
                    if (a6 && a6.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a6.length) {
                        conditionTexts.push(rootState.constants.locations[k - 1].name)
                      } else {
                        a6SmallCount += 1;
                        // a6.forEach((obj) => {
                        //   conditionTexts.push(obj.name);
                        // });
                      }
                    }
                    break
                  case 7:  // 関西
                    if (a7 && a7.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a7.length) {
                        conditionTexts.push(rootState.constants.locations[k - 1].name)
                      } else {
                        a7SmallCount += 1;
                        // a7.forEach((obj) => {
                        //   conditionTexts.push(obj.name);
                        // });
                      }
                    }
                    break
                  case 8:  // 中国・四国
                    if (a8 && a8.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a8.length) {
                        conditionTexts.push(rootState.constants.locations[k - 1].name)
                      } else {
                        a8SmallCount += 1;
                        // a8.forEach((obj) => {
                        //   conditionTexts.push(obj.name);
                        // });
                      }
                    }
                    break
                  case 9:  // 九州・沖縄
                    if (a9 && a9.length > 0) {
                      if (rootState.constants.locations[k - 1].children.length === a9.length) {
                        conditionTexts.push(rootState.constants.locations[k - 1].name)
                      } else {
                        a9SmallCount += 1;
                        // a9.forEach((obj) => {
                        //   conditionTexts.push(obj.name);
                        // });
                      }
                    }
                    break
                  default:
                    break
                }
              }

              // 小分類を大分類を入れた後から入れる
              if (a1SmallCount != 0) {
                a1.forEach((obj) => {
                  conditionTexts.push(obj.name);
                });
              }
              if (a2SmallCount != 0) {
                a2.forEach((obj) => {
                  conditionTexts.push(obj.name);
                });
              }
              if (a3SmallCount != 0) {
                a3.forEach((obj) => {
                  conditionTexts.push(obj.name);
                });
              }
              if (a4SmallCount != 0) {
                a4.forEach((obj) => {
                  conditionTexts.push(obj.name);
                });
              }
              if (a5SmallCount != 0) {
                a5.forEach((obj) => {
                  conditionTexts.push(obj.name);
                });
              }
              if (a6SmallCount != 0) {
                a6.forEach((obj) => {
                  conditionTexts.push(obj.name);
                });
              }
              if (a7SmallCount != 0) {
                a7.forEach((obj) => {
                  conditionTexts.push(obj.name);
                });
              }
              if (a8SmallCount != 0) {
                a8.forEach((obj) => {
                  conditionTexts.push(obj.name);
                });
              }
              if (a9SmallCount != 0) {
                a9.forEach((obj) => {
                  conditionTexts.push(obj.name);
                });
              }

            }
            
          // 想定稼働時間（週）
          } else if(data[0].code.indexOf("H") != -1) {
            console.log('data', data)
            for(const i in data) {
              conditionTexts.push('週' + data[i].name)
            }
          }
        } else {
          // その他の検索条件の時
          data.forEach((code) => {
            conditionTexts.push(code.name);
          });
        }

        if (conditionTexts.length > 2) {
          searchConditionTexts.push(
            `${conditionTexts[0]}、${conditionTexts[1]}など`
          );
        } else {
          searchConditionTexts.push(conditionTexts.join("、"));
        }
      }
    },
    createParentCategoryText: () => (parentObject, childrenArray) => {
      if (childrenArray && childrenArray.length > 0) {
        return parentObject.name;
      } else {
        return null;
      }
    },
    isSameAsParentCode: () => (parentObject, childrenArray) => {
      // childrenArray.lengthがconstants内のparentCodeの中身全てと同じだったらtrue
      if (parentObject && parentObject.children) {
        if (childrenArray.length === parentObject.children.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false
      }
    },
    cleanPrefCategory: (state) => (parentObj, sortedArray, checkedLocation) => {
      if (parentObj.children.length === sortedArray.length) {
        // sortされた内容（親コードのchildren）をstate.checkedJobCategoriesから抜く
        var newArray = []
        newArray = state.checkedLocations.filter((pref) => {
          return !parentObj.children.includes(pref)
        })

        var parentArray = checkedLocation.filter((d) => {
          return d.code == parentObj.code
        })

        // 既に親コードが入っていなかったら追加する
        if (!(parentArray && parentArray.length > 0)) {
          // state.checkedJobCategoriesの最後に親objを入れる
          newArray.push(parentObj)
        }

        state.checkedLocations = newArray
      } else {
        // 親コードも子コードもあるけど親コードにならなかった場合
        state.checkedLocations = state.checkedLocations.filter((d) => {
          return d.code != parentObj.code
        })
      }
    },
  },
  modules: {
  }
}